import "./Post.css"

export default function Post({ title, username, content }) {
  return (
    <div className="post">
      <h2>{title}</h2>
      <p className="username">{username}</p>
      <p>{content}</p>
    </div>
  )
}