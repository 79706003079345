import { Link } from "@reach/router"
import { useEffect, useState } from "react"
import Post from "../Post/Post"
import "./Posts.css"

export default function Posts() {
  const [posts, setPosts] = useState([])
  const [error, setError] = useState("")
  
  useEffect(() => {
    const getPosts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/posts`
        )
        if (response.status === 200) {
          const postsResponse = await response.json() || []
          setPosts(postsResponse)
          setError("")
        } else {
          setError(await response.text())
        }
      } catch (error) {
        setError(error.message || error)
      }
    }
    getPosts()
  }, [])

  return (
    <div className="posts">
      <div className="titleBar">
        <h1>Posts</h1>
        <Link to="/create">Create a post!</Link>
      </div>
      <div className="postList">
        {error ? 
          <p style={{ color: 'red' }}>{error}</p> : 
          posts.map(post => <Post key={post.id} {...post} />)
        }
      </div>
    </div>
  )
}