import { Link, navigate } from "@reach/router"
import { useState } from "react"
import "./CreatePost.css"

export default function CreatePost() {
  const [title, setTitle] = useState("")
  const [username, setUsername] = useState("")
  const [content, setContent] = useState("")
  const [error, setError] = useState("")
  
  const submitPost = async (event) => {
    try {
      event.preventDefault()
      if (!title || !username || !content) {
        throw new Error("Please fill out all fields!")
      }
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/posts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: JSON.stringify({ title, username, content })
      })
      if (response.status === 200) {
        navigate("/")
        setError("")
      } else {
        setError(await response.text())
      }
    } catch (error) {
      setError(error.message || error)
    }
  }
  const updateInput = (event, cb) => {
    cb(event.target.value)
  }

  return (
    <div className="container">
      <Link to="/">Go Home</Link>
      <h1>Create a post!</h1>
      <form onSubmit={submitPost}>
        <div className="section">
          <label htmlFor="title">Title</label>
          <input type="text" name="title" value={title} onChange={(e) => updateInput(e, setTitle)} />
          <br />
        </div>
        <div className="section">
          <label htmlFor="username">Username</label>
          <input type="text" name="username" value={username} onChange={(e) => updateInput(e, setUsername)} />
          <br />
        </div>
        <div className="section">
          <label htmlFor="content">Content</label>
          <textarea name="content" value={content} onChange={(e) => updateInput(e, setContent)} />
          <br />
        </div>
        <input type="submit" className="submit" />
      </form>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  )
}