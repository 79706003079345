import { Router } from "@reach/router"
import CreatePost from "./components/CreatePost/CreatePost"
import Posts from "./components/Posts/Posts"

function App() {
  return (
    <Router>
      <Posts path="/"/>
      <CreatePost path="/create"/>
    </Router>
  )
}

export default App
